import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "label", "button"];

  connect() {
    this.inputTarget.value = this.inputTarget.min;
    this.update();
  }

  update() {
    this.updateLabel();
    this.updateButton();
  }

  updateLabel() {
    const value = this.inputTarget.value;
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });
    this.labelTarget.textContent = formatter.format(value);
  }

  updateButton() {
    const value = this.inputTarget.value;
    this.buttonTarget.dataset.commandDataValue = JSON.stringify({
      amount: Number(value),
    });
  }
}
