import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static values = {
    roll: Number,
  };

  static targets = ["dice", "roll"];

  connect() {
    this.notify = this.notify.bind(this);
    this.diceTarget.addEventListener("transitionend", this.notify);
    this.roll();
  }

  disconnect() {
    this.diceTarget.removeEventListener("transitionend", this.notify);
  }

  roll() {
    const roll = this.rollValue;

    for (let i = 1; i <= 6; i++) {
      this.diceTarget.classList.remove("show-" + i);
      if (roll === i) {
        this.diceTarget.classList.add("show-" + i);
      }
    }
  }

  notify() {
    this.diceTarget.dispatchEvent(
      new CustomEvent("dice:rolled", {
        bubbles: true,
        detail: { roll: this.rollValue },
      })
    );
  }
}
