import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["mute", "unmute"];

  connect() {
    if (window.localStorage.getItem("soundsMuted")) {
      this.mute();
    } else {
      this.unmute();
    }
  }

  mute() {
    // localstorage still to do
    this.muteTarget.classList.add("hidden");
    this.unmuteTarget.classList.remove("hidden");
    document.querySelectorAll("audio").forEach((element) => element.volume = 0);
    window.localStorage.setItem("soundsMuted", "muted");
  }

  unmute() {
    this.unmuteTarget.classList.add("hidden");
    this.muteTarget.classList.remove("hidden");
    document.querySelectorAll("audio").forEach((element) => element.volume = 1);
    window.localStorage.removeItem("soundsMuted");
  }
}
