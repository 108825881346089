import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.element.addEventListener("keypress", this.validateInput);
  }

  disconnect() {
    this.element.removeEventListener("keypress", this.validateInput);
  }

  validateInput(event) {
    const isDigit = /[0-9]/i.test(event.key);
    if (!isDigit) {
      event.preventDefault();
    }
  }
}
