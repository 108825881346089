import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["revealed"];
  static targets = ["content"];

  reveal() {
    this.contentTarget.classList.add(this.revealedClass);
  }
}
