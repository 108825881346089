import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "block"];

  connect() {
    this.showHideBlocks();
  }

  showHideBlocks() {
    let enabled_mods = this.inputTarget.selectedOptions[0].dataset.enabledMods;
    this.blockTargets.forEach((el) => {
      enabled_mods = enabled_mods || ""
      let mod_enabled = enabled_mods.split(" ").includes(el.dataset.modName);
      el.classList.toggle("hidden", !mod_enabled);
    });
  }
}
