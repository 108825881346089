import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    type: String,
    data: Object,
  };

  async command() {
    // assume we're on a game page
    const url = new URL(document.location);
    url.pathname += "/command";

    const token = document.querySelector("meta[name='csrf-token']").content;
    const body = JSON.stringify(this.#payload());

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body,
    });
    if (response.status != 201) {
      const json = await response.json();
      console.error(json);

      if (json.hasOwnProperty("game_error")) {
        this.#throwGameError(json);
      } else if (json.hasOwnProperty("validation_errors")) {
        this.#throwValidationError(json);
      }
    }
  }

  #throwGameError(detail) {
    this.element.dispatchEvent(
      new CustomEvent("game_error", {
        detail,
        bubbles: true,
      })
    );
  }

  #throwValidationError(detail) {
    this.element.dispatchEvent(
      new CustomEvent("validation_error", {
        detail,
        bubbles: true,
      })
    );
  }

  #payload() {
    return {
      command: {
        type: this.typeValue,
        data: this.dataValue,
      },
    };
  }
}
