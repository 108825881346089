import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    target: String,
  };

  reload() {
    document.getElementById(this.targetValue).reload();
  }
}
