import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["option", "repurchaseCost", "selectAllCheckbox", "button"];

  optionTargetConnected(element) {
    let input = document.getElementById(element.dataset.input);
    input.checked = true;
  }

  selectAllCheckboxTargetConnected(element) {
    element.checked = true;
  }

  connect() {
    this.processSelectionUpdate();
  }

  processSelectAll(element) {
    let checked = element.srcElement.checked;

    if (this.hasOptionTarget) {
      this.optionTargets.forEach((option) => {
        let input = document.getElementById(option.dataset.input);
        input.checked = checked;
      });
    }

    this.processSelectionUpdate();
  }

  processSelectionUpdate() {
    let repurchaseCost = 0;
    let selectedDefences = [];

    // Loop through the options
    if (this.hasOptionTarget) {
      this.optionTargets.forEach((option) => {
        // Add to the repurchase cost if the option is selected
        let input = document.getElementById(option.dataset.input);
        if (input.checked) {
          repurchaseCost += parseFloat(option.dataset.amount);
          selectedDefences.push(option.dataset.key);
        }
      });
    }

    // Update the displayed repurchase value
    this.repurchaseCostTarget.innerText = `£${this.kFormatter(repurchaseCost)}`;

    // Configure the button depending on whether repurchase is allowed based on the currently selected options
    if (this.hasButtonTarget) {
      // Update the data values for the command button
      this.buttonTarget.dataset.commandDataValue = JSON.stringify({
        defences: selectedDefences,
      });
      if (repurchaseCost <= parseFloat(this.buttonTarget.dataset.budget)) {
        this.buttonTarget.disabled = false;
        delete this.buttonTarget.dataset.tooltip;
      } else {
        this.buttonTarget.disabled = true;
        this.buttonTarget.dataset.tooltip =
          this.buttonTarget.dataset.unaffordable;
      }
    }
  }

  kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  }
}
