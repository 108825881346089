import c0 from './auto_command_controller';
import c1 from './dialog_controller';
import c2 from './error_controller';
import c3 from './frame_controller';
import c4 from './loan_controller';
import c5 from './new_game_form_controller';
import c6 from './repurchase_controller';
import c7 from './reveal_controller';
import c8 from './timer_controller';
export const definitions = [
	{identifier: 'auto-command', controllerConstructor: c0},
	{identifier: 'dialog', controllerConstructor: c1},
	{identifier: 'error', controllerConstructor: c2},
	{identifier: 'frame', controllerConstructor: c3},
	{identifier: 'loan', controllerConstructor: c4},
	{identifier: 'new-game-form', controllerConstructor: c5},
	{identifier: 'repurchase', controllerConstructor: c6},
	{identifier: 'reveal', controllerConstructor: c7},
	{identifier: 'timer', controllerConstructor: c8},
];
