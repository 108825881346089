import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["minutes", "seconds"];

  static values = {
    date: String,
  };

  connect() {
    this.update();
    this.update = this.update.bind(this);
    this.intervalId = setInterval(this.update, 1000);
  }

  disconnect() {
    clearInterval(this.intervalId);
  }

  update() {
    const deltaMillis = Date.now() - Date.parse(this.dateValue);
    let deltaSeconds = Math.floor(deltaMillis / 1000);
    const deltaMinutes = Math.floor(deltaSeconds / 60);
    deltaSeconds -= deltaMinutes * 60;
    this.minutesTarget.textContent = String(deltaMinutes).padStart(2, "0");
    this.secondsTarget.textContent = String(deltaSeconds).padStart(2, "0");
  }
}
