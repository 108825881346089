import c0 from './command_controller';
import c1 from './dice_controller';
import c2 from './dice_show_controller';
import c3 from './mute_sounds_controller';
import c4 from './numeric_input_controller';
import c5 from './password_peek_controller';
import c6 from './sound_effects_controller';
import c7 from './toggle_controller';
export const definitions = [
	{identifier: 'command', controllerConstructor: c0},
	{identifier: 'dice', controllerConstructor: c1},
	{identifier: 'dice-show', controllerConstructor: c2},
	{identifier: 'mute-sounds', controllerConstructor: c3},
	{identifier: 'numeric-input', controllerConstructor: c4},
	{identifier: 'password-peek', controllerConstructor: c5},
	{identifier: 'sound-effects', controllerConstructor: c6},
	{identifier: 'toggle', controllerConstructor: c7},
];
