import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["error"];

  connect() {
    this.onGameError = this.onGameError.bind(this);
    this.onValidationError = this.onValidationError.bind(this);
    this.element.addEventListener("game_error", this.onGameError);
    this.element.addEventListener("validation_error", this.onValidationError);
  }

  disconnect() {
    this.element.removeEventListener("game_error", this.onGameError);
    this.element.removeEventListener(
      "validation_error",
      this.onValidationError
    );
  }

  onGameError(event) {
    event.stopPropagation();
    const { game_error: message } = event.detail;
    this.errorTarget.textContent = `Error: ${message}`;
  }

  onValidationError(event) {
    event.stopPropagation();
    const { validation_errors: message } = event.detail;
    this.errorTarget.textContent = `Error: ${message.join(", ")}`;
  }
}
