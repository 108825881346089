import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["hidden"];
  static targets = ["rolling", "rolled"];

  connect() {
    this.showContent = this.showContent.bind(this);
    this.element.addEventListener("dice:rolled", this.showContent);
  }

  disconnect() {
    this.element.removeEventListener("dice:rolled", this.showContent);
  }

  showContent() {
    requestAnimationFrame(() => {
      this.rollingTargets.forEach((el) => el.classList.add(this.hiddenClass));
      this.rolledTargets.forEach((el) => el.classList.remove(this.hiddenClass));
    });
  }
}
